import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import {Button} from 'gatsby-theme-material-ui'

import Layout from '../components/layout'
import { Typography, Box, Container, Paper, Grid } from '@material-ui/core'

const OnamaPage = ({ data }) => {
    
  const image = data.allFile.nodes[0]
  return (
    <Layout fixed title="O nama">
        <Typography paragraph variant="h3" color="primary" align="left">
        Gloria d.o.o. | O nama
        </Typography>
        <Typography paragraph variant="body1">
Osnovan: 1994.<br/>
Poslovne aktivnosti:
<ul>
    <li>poduka iz engleskog, njemačkog, talijanskog, francuskog i španjolskog jezika za učenike osnovnih i
srednjih škola te za odrasle.</li>
    <li>igraonica na engleskom jeziku za djecu od 3,5 godine,</li>
    <li>predškolski engleski,</li>
    <li>ljetna škola engleskog jezika u Engleskoj, Škotskoj, Irskoj i Americi</li>
    <li>prevođenje dokumenata, priručnika, dopisa, itd. sa stranog i na strani jezik</li>
    <li>poslovni tečajevi engleskog, njemačkog i talijanskog jezika</li>
</ul>
<br/>
Metoda rada i programi prilagođavaju se različitim starosnim skupinama, a sve veći broj polaznika
daje nam jamstvo da naš rad postiže dobre rezultate, a ujedno nam daje i poticaj za daljnje
usavršavanje.<br/>
Sudionici smo Addvantage programa u suradnji s British Council i ovlašteni za pripremu sljedećih
Međunarodnih ispita:<br/>
IELTS - ispit koji otvara sva vrata (vize, studiranje, posao, stručna usavršavanja)<br/><br/>
Opći engleski - osnovnoškolci: Starters, Movers, Flyers<br/>
- srednjoškolci i odrasli: KET, PET, FCE, CAE, CPE<br/><br/>
POSLOVNI ENGLESKI: BEC Perliminary, BEC Vantage, BEC Higher<br/>
ILEC - ispit iz područja medunarodnog prava<br/>
ICFE - ispit iz područja medunarodnog bankarstva i računovodstva<br/>
        </Typography>
        <Button to="/kontakt" color="primary" variant="contained">Kontaktirajte nas!</Button>

    </Layout>
  )
}
export const query = graphql`

    query {
        allFile(filter: { relativePath: { eq: "gloria/polica_2.jpg" } }) {
          nodes {
            childImageSharp {
              fluid(
                maxWidth: 1200
                srcSetBreakpoints: [200, 340, 480, 900]
                quality: 80
              ) {
                ...GatsbyImageSharpFluid
                originalName
                aspectRatio
              }
            }
          }
        }
      }

`

export default OnamaPage
